import { IMedication, GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { IMedicationState, EnMedicationPeriod, IMedicationPeriod, IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createSelector, createFeatureSelector, select } from '@ngrx/store';
import { map, pipe } from 'rxjs';

import * as fromMedication from './medication.reducer';
import * as fromPatient from './../../state-patient/+state/patient.selectors';
import * as fromPerson from './../../state-person/+state/person.selectors';

const glb = new GlobalService();
const log = new LogService();


// TODO: Descobrir se há outra forma de criar um "namespace" para que os selectors não colidam
// export const selectMedicationIds = selectIds;
// export const selectMedicationEntities = selectEntities;
// export const selectMedicationAlls = selectAll;
// export const selectMedicationTotal = selectTotal;

export const getCodPaciente = (medication: IMedication | any): number => {
  try {
    const split = medication?.codPacienteNomedoPacienteCodMedicamento?.indexOf('__') >= 0 ?
      medication?.codPacienteNomedoPacienteCodMedicamento?.split('__') :
      medication?.codPacienteNomedoPacienteCodMedicamento?.split('_');
    const codPacient = split && split.length > 0 ? +split[0] : null;
    return medication?.patientId || medication?.Id_Paciente || medication?.codigoPaciente || medication?.codigoHospede || codPacient || -1;
  } catch (error: any) {
    console.log('medication.selectors', 'getCodPaciente', error.message);
  }
  return -1;
};

export const selectMedicationState = createFeatureSelector<IMedicationState>(fromMedication.medicationsFeatureKey);
export const selectMedicationAlls = createSelector(selectMedicationState, fromMedication.selectAll);

export const selectMedicationAllSortByOrder = createSelector(
  selectMedicationAlls,
  (medications) => medications?.map(m => ({ ...m, order: m.order || 0 })).sort((a, b) => (+a.order) - (+b.order))
);

/* Considera a ordenação alfabética, mas também nome ou ocorrenciaNo vazio para fazer com que o novo medicamento seja acrescentado e permanesça no final da lista.  */
export const selectMedicationAllSortByMedicationName = createSelector(
  selectMedicationAlls,
  (medications) => medications?.sort((a, b) => a.isNew || glb.isNullOrEmpty(a.medicationName) || (glb.isNullOrEmpty(a.ocorrenciaNo) ? true : a.ocorrenciaNo < 0) ? 1 : a.medicationName.localeCompare(b.medicationName))
);

export const selectMedicationKeyword = (state: IAppMedlogicState) => state.medication.keyword;

/** Filtrados por palavra-chave, ou novo registro, ainda sem número de ocorrência. */
export const selectMedicationAllFilteredByKeyword = createSelector(
  selectMedicationAlls,
  selectMedicationKeyword,
  (medications, keyword) => [...glb.isNullOrEmpty(keyword) ? medications : medications?.filter(f => f.medicationName?.toUpperCase().includes(keyword?.toUpperCase()) || glb.isNullOrEmpty(f.ocorrenciaNo))]
);

export const selectMedicationAllSortByMedicationNameFilteredByKeyword = pipe(
  select(selectMedicationAllFilteredByKeyword),
  map((medications) => {
    return [...medications?.sort((a, b) => a.isNew || glb.isNullOrEmpty(a.medicationName) || (glb.isNullOrEmpty(a.ocorrenciaNo) ? true : a.ocorrenciaNo < 0) ? 1 : a.medicationName?.localeCompare(b.medicationName))];
  })
);

export const selectRawMedications = createSelector(
  selectMedicationState,
  (state) => state?.rawMedications
);

export const selectHistoryMedications = createSelector(
  selectMedicationState,
  (state) => state?.historyMedications
);

export const isMedicationLoading = createSelector(
  selectMedicationState,
  state => state.isLoading
);

export const selectMedicationsBySelectedPerson = createSelector(
  selectMedicationAlls,
  fromPerson.selectPerson,
  (medications, person) => medications?.filter(f => getCodPaciente(f) === +person.prontuario)
);

/* Verifica se todos os medicamentos estão preenchidos e se todos atendem a condições mínimas de preenchimento. */
export const selectWasMedicationsChanged = createSelector(
  selectMedicationAlls,
  (medications) => medications?.filter(f => f.wasChanged)?.length > 0 &&
    medications?.filter(f =>
      glb.isNullOrEmpty(f.medicationName) ||
      glb.isNullOrEmpty(f.dosage) ||
      // glb.isNullOrEmpty(f.controlado) ||
      glb.isNullOrEmpty(f.enPosology) ||
      glb.isNullOrEmpty(f.tempoUso) ||
      glb.isNullOrEmpty(f.dailyQuantity) ||
      glb.isNullOrEmpty(f.presentation)
    )?.length === 0
);

export const selectMedicationsByPatientSelected = createSelector(
  selectMedicationAlls,
  fromPatient.selectedPatientId,
  (medications, patientId) => medications?.filter(f => getCodPaciente(f) === +patientId)
);

export const selectMedicationsByPatientSelectedAndFilteredByActive = createSelector(
  selectMedicationsByPatientSelected,
  medications => medications?.filter(f =>
    f.stopMedication ||
    (
      glb.isEqual(f.tempoUso, 'Ocasional') &&
      glb.isBetweenIgnoreTime(new Date(), glb.getTypedValue(f.dtStart).value, glb.getTypedValue(f.dtEnd).value)
    ) ||
    (
      !glb.isEqual(f.tempoUso, 'Ocasional') &&
      glb.isBetweenIgnoreTime(new Date(), glb.getTypedValue(f.dtStart).value, new Date(2100, 0, 1))
    )
  )
);

export const selectMedicationsByPatient = (patientId: number) => createSelector(
  selectMedicationAlls,
  (medications) => medications?.filter(f => getCodPaciente(f) === +patientId)
);

/* Retorna o número total de medicamentos cadastrados para o paciente */
export const selectNumOfMedicationsByPatient = (patientId: number) => createSelector(
  selectMedicationsByPatient(patientId),
  medications => medications?.length ? medications?.filter(f => !f.took && !f.stopMedication).length : 0
);

export const selectHasMedicationsByPatient = (patientId: number, calcMedicationsCount: number = null) => createSelector(
  selectMedicationsByPatient(patientId),
  medications => medications?.length > 0 || calcMedicationsCount > 0 ? true : false
);

/* Expressão padrão, com base no horário.
 * Também não entrarão as medicações de SOS e interrupção.
 */
const filterStandard = (medication: IMedication, startHour: number, endHour: number, enPeriod: EnMedicationPeriod): boolean => {
  try {
    // startHour = startHour === 0 ? 24 : startHour;
    // endHour = endHour === 0 ? 24 : endHour;
    return (enPeriod === EnMedicationPeriod.timeInterval) &&
      !medication.stopMedication && !medication.isSOS &&
      (glb.getHora(medication.prescribedTime) >= startHour &&
        glb.getHora(medication.prescribedTime) <= endHour);
  } catch (error) {
    console.log('medication.selectors', 'filterStandard', error.message);
  }
}

/* Expressão personalizada de filtro para SOS. */
const filterSOS = (medication: IMedication, enPeriod: EnMedicationPeriod): boolean => {
  try {
    return (enPeriod === EnMedicationPeriod.SOS) && medication.isSOS && !medication.stopMedication;
  } catch (error) {
    console.log('medication.selectors', 'filterSOS', error.message);
  }
}

/* Expressão personalizada de filtro para Interromper Medicação. */
const filterStopMedication = (medication: IMedication, enPeriod: EnMedicationPeriod): boolean => {
  try {
    return (enPeriod === EnMedicationPeriod.stop) && medication.stopMedication;
  } catch (error) {
    console.log('medication.selectors', 'filterStopMedication', error.message);
  }
}

/* Organiza as medicações conforme o período de ministração. */
// FIXME: Esse seria o padrão de implementação ideal, mas no card-medication, não consegui realizar a chamada passando patientId.
// export const selectMedicationByPatientGroupedByPeriod = (patientId: number) => createSelector(
//   selectMedicationsByPatient(patientId),
//   (state: any, medications: IMedication[]) => {
//     const medicationPeriods = [
//       // tslint:disable: max-line-length
//       { enMedicationPeriod: EnMedicationPeriod.stop, title: 'Interromper', filter: filterStopMedication, styleCss: 'stop' } as IMedicationPeriod,
//       { enMedicationPeriod: EnMedicationPeriod.SOS, title: 'SOS', filter: filterSOS, styleCss: 'sos' } as IMedicationPeriod,
//       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Manhã', startHour: 0, endHour: 11, styleCss: 'morning' } as IMedicationPeriod,
//       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Tarde', startHour: 12, endHour: 18, styleCss: 'afternoon' } as IMedicationPeriod,
//       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Noite', startHour: 19, endHour: 24, styleCss: 'night' } as IMedicationPeriod,
//     ];
//     medicationPeriods.forEach(e =>
//       e.medications = medications
//         .filter(f => {
//           if (f.stopMedication) {
//             return filterStopMedication(f, e.enMedicationPeriod);
//           } else if (f.isSOS) {
//             return filterSOS(f, e.enMedicationPeriod);
//           } else {
//             return filterStandard(f, e.startHour, e.endHour, e.enMedicationPeriod);
//           }
//         })
//         .sort((a, b) => (glb.getHora(a.prescribedTime) - glb.getHora(b.prescribedTime)) || (glb.CompararIgnorarCapitulacaoEAcentuacao(a.medicationName, b.medicationName)))
//     );
//     return medicationPeriods;
//   });

/* Organiza as medicações conforme o período de ministração. */
export const selectMedicationByPatientGroupedByPeriod = createSelector(
  selectMedicationsByPatientSelectedAndFilteredByActive,
  fromPatient.selectedPatientId,
  (medicationsByPatient, patientId) => {
    try {
      // const patientId: number = state.patient.selectedId;
      if (patientId > 0 && medicationsByPatient?.length > 0) {
        const medicationPeriods = [
          { enMedicationPeriod: EnMedicationPeriod.stop, title: 'Interromper', filter: filterStopMedication, styleCss: 'stop' } as IMedicationPeriod,
          { enMedicationPeriod: EnMedicationPeriod.SOS, title: 'SOS', filter: filterSOS, styleCss: 'sos' } as IMedicationPeriod,
          { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Manhã', startHour: 0, endHour: 11, styleCss: 'morning' } as IMedicationPeriod,
          { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Tarde', startHour: 12, endHour: 17, styleCss: 'afternoon' } as IMedicationPeriod,
          { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Noite', startHour: 18, endHour: 23, styleCss: 'night' } as IMedicationPeriod,
        ];
        medicationPeriods.forEach(e =>
          // e.medications = medications?.filter(f => getCodPaciente(f) === +patientId)
          e.medications = medicationsByPatient
            .filter(f => {
              if (f.stopMedication) {
                return filterStopMedication(f, e.enMedicationPeriod);
              } else if (f.isSOS) {
                return filterSOS(f, e.enMedicationPeriod);
              } else {
                const filtered = filterStandard(f, e.startHour, e.endHour, e.enMedicationPeriod);
                return filtered;
              }
            })
            .sort((a, b) => (glb.toMinute(a.prescribedTime) - glb.toMinute(b.prescribedTime)) || (glb.CompararIgnorarCapitulacaoEAcentuacao(a.medicationName, b.medicationName)))
        );
        return medicationPeriods;
      }
    } catch (error) {
      log.Registrar('medication.selectors', 'selectMedicationByPatientGroupeByPeriod', error.message);
    }
    return undefined;
  });
