import { CadastroService } from "@medlogic/shared/shared-data-access";
import {
  GlobalService, IServiceProvider, LogService, IForm,
  LocalLibService, IPrescriptionMedication,
  IInterventionMedication, error
} from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from "@medlogic/shared/state-config";
import { Observable, of } from "rxjs";
import { map, publishReplay, refCount, toArray, mergeMap, groupBy } from 'rxjs/operators';

export abstract class PrescriptionMedicationService implements IServiceProvider {
  private lstVariaveis =
    'V_387,V_391,V_1608,V_3328,V_3329,V_27993,V_28051,V_29828,V_32935,V_32936,V_32937,V_32938,V_32939,V_32940,V_32941,V_32942,V_33963';
  private variavelGrid = 'V_30354';
  private lstVariaveisGrid =
    'V_828,V_830,V_832,V_2949,V_2952,V_3530,V_27993,V_28018,V_29828,V_29834,V_29838,V_30392,V_30393,V_30214,V_30263,V_30264,V_30266,V_30277,V_30296,V_30320,V_30330,V_30339,V_30356,V_30367,V_30396,V_30401,V_31571,V_31967,V_31968,V_31969,V_32857,V_32858,V_32859,V_32860,V_32861,V_32862,V_32910,V_32911,V_32913,V_32914,V_32939,V_34286,V_34465,V_34709,V_35051,V_101099,V_101103,V_104223';

  recurrences: IPrescriptionMedication[] = new Array<
    IPrescriptionMedication
  >();

  codigoVariavelNo = 32935; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 4694;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected lib: LocalLibService,
    protected log: LogService
  ) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
   * lstVariables do tipo: 'V_3332,V_32223'
   */
  getSome(
    ano: number,
    lstVariables: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<IPrescriptionMedication> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(
        this.cadastroNo,
        startDate,
        endDate,
        lstVariables
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(
    ano: number,
    startDate?: Date,
    endDate?: Date
  ): Observable<IPrescriptionMedication> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(
        this.getAll(ano),
        propLabel,
        propValue,
        propEnabled
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null
  ): Observable<IPrescriptionMedication> {
    try {
      if (
        !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
        !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
        !this.cadastrosCache
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastroGrid(
          cadastroNo,
          startDate,
          endDate,
          lstVariables
        );
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate)
        .pipe(this.mapTo(), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getFromCadatro',
        error.message
      );
    }
    return of(null);
  }

  protected mapTo = () =>
    map((c: any) => {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        codRg: c.V_32964,
        residente: c.V_387,
        dataNascimento: c.V_391,
        nomemae: c.V_1608,
        identificacao1: c.V_3328,
        identificacao2: c.V_3329,
        centroCusto: c.V_27993,
        prontuario: c.V_28051,
        idPaciente: c.V_29828,
        codigo32935: c.V_32935,
        titulo: c.V_32936,
        habilitado: this.lib.getBoolean(c.V_32937),
        nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_32938),
        codHospedeTelaPrescricao: c.V_32939,
        codNome: c.V_32940,
        codigoHospede: c.V_32941,
        codigoNome: c.V_32942,
        responsavelNome: c.V_33963,
        // responsavelRG: c.V_33964,
        // codHospede: c.V_34279,
      } as IPrescriptionMedication;
    });

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
   * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date = null,
    endDate: Date = null,
    isFilterAnd: boolean = true,
    lstVariables: string = null
  ): Observable<IPrescriptionMedication> {
    try {
      this.cadastroSrv.dtInicial = startDate
        ? this.glb.dateToYYYYMMddThhmmss(startDate)
        : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate
        ? this.glb.dateToYYYYMMddThhmmss(endDate)
        : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(
          cadastroNo,
          lstVariables,
          strFilter,
          isFilterAnd,
          startDate,
          endDate
        )
        .pipe(this.mapTo(), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }



  /* Insere ou atualiza o item.
* Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
*/
  save<T>(ano: number, prescriptionMedication: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(prescriptionMedication as unknown as IPrescriptionMedication).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo, false) // Notificar conclusão modificada para false, pois agora, é BD relacional.
        .pipe(
          map(newId => ({ ...prescriptionMedication, codigo32935: newId, ocorrenciaNo: newId } as T)),
          error()
        );

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(
    prescriptionMedication: IPrescriptionMedication
  ): IForm[] {
    try {
      return [
        { VariavelNo: 32936, ValorDado: prescriptionMedication.titulo || '' },
        {
          VariavelNo: 32935,
          ValorDado: prescriptionMedication.codigo32935 || '',
        },
        {
          VariavelNo: 32937,
          ValorDado: prescriptionMedication.habilitado ? 'SIM' : 'NÃO',
        },
        { VariavelNo: 387, ValorDado: prescriptionMedication.residente || '' },
        {
          VariavelNo: 391,
          ValorDado: prescriptionMedication.dataNascimento || '',
        },
        { VariavelNo: 1608, ValorDado: prescriptionMedication.nomemae || '' },
        {
          VariavelNo: 3328,
          ValorDado: prescriptionMedication.identificacao1 || '',
        },
        {
          VariavelNo: 3329,
          ValorDado: prescriptionMedication.identificacao2 || '',
        },
        {
          VariavelNo: 27993,
          ValorDado: prescriptionMedication.centroCusto || '',
        },
        {
          VariavelNo: 28051,
          ValorDado: prescriptionMedication.prontuario || '',
        },
        {
          VariavelNo: 29828,
          ValorDado: prescriptionMedication.idPaciente || '',
        },
        {
          VariavelNo: 32938,
          ValorDado: this.glb.ddMMYYYYThhmmssToDate(
            prescriptionMedication.nascimento
          ),
        },
        {
          VariavelNo: 32939,
          ValorDado: prescriptionMedication.codHospedeTelaPrescricao || '',
        },
        { VariavelNo: 32940, ValorDado: prescriptionMedication.codNome || '' },
        {
          VariavelNo: 32941,
          ValorDado: prescriptionMedication.codigoHospede || '',
        },
        {
          VariavelNo: 32942,
          ValorDado: prescriptionMedication.codigoNome || '',
        },
        {
          VariavelNo: 33963,
          ValorDado: prescriptionMedication.responsavelNome || '',
        },
        {
          VariavelNo: 32964,
          ValorDado: prescriptionMedication.codRg || '',
        },
        // {
        //   VariavelNo: 34279,
        //   ValorDado: prescriptionMedication.codHospede || '',
        // },
        {
          VariavelNo: 30354, // GRID apontando para o cadastro INTERVENÇÕES MEDICAMENTOS V2
          ValorDado: this.convertToValorTexto(
            prescriptionMedication.lstmedicationGrid
          ),
        },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }



  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(ano: number, prescriptionMedication: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], prescriptionMedication[compareFieldName])) >= 0);
            if (!founded) {
              return this.save(ano, prescriptionMedication as unknown as IPrescriptionMedication, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }

  protected getFromCadastroGrid(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null
  ): Observable<IPrescriptionMedication> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      // Resgata os dados que duplicam os registros para cada item do grid e os agrupa por OcorrenciaNo
      const grouped = this.cadastroSrv
        .getCadastroComGridFiltro(
          cadastroNo,
          -1,
          this.lstVariaveis,
          this.variavelGrid,
          this.lstVariaveisGrid,
          ''
        )
        .pipe(
          publishReplay(),
          refCount(),
          groupBy((g) => g.OcorrenciaNo)
        );
      // Criação do Observable que será o formato de retorno
      return new Observable((observer) => {
        let prescriptionMedication: IPrescriptionMedication;
        // Subscrição para os grupos

        grouped
          .subscribe(group => { // Subscrição para acessar os dados de cada grupo
            group.pipe(toArray())
              .subscribe(item => {
                // Todos os elementos do grupo são iguais, exceto pelos campos do grid
                // por esse motivo, é possível utilizar como base para os demais dados qualquer elementos
                const c = item[0];
                // Criação de um array com os percentuais e valores por unidade de negócio
                const lstmedicationGrid: IInterventionMedication[] =
                  item.length <= 0
                    ? null
                    : item.map(
                      (m) =>
                      ({
                        codigo: c.V_30392, // V_3021*2 V_30392
                        titulo: c.V_30393,   // V_3021*3, V_30393
                        habilitado: this.glb.getBoolean(c.V_30214),
                        medicamento: c.V_828,
                        tempoUso: c.V_830,
                        dosagem: c.V_832,
                        via: c.V_2949,
                        orientacoes: c.V_2952,
                        posologia: c.V_3530,
                        centrocusto: c.V_27993,
                        codigoPaciente: c.V_29828,
                        apresentacao: c.V_29834,
                        quantUtilizadaD: this.glb.getSomeNumber('-1', c.V_29838, c.V_101103),
                        quantUtilizadaGota: this.glb.getSomeNumber('-1', c.V_104223),
                        hojedia: this.glb.ddMMYYYYThhmmssToDate(c.V_29949),
                        // codigomedicamento: c.V_30263, não existe mais no mapeamento, é Id_medicamento
                        cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: c.V_30264,
                        centroCustoIDCentroCusto: c.V_30266,
                        tIPOMedicamentos: c.V_30270,
                        tipomedicamento: c.V_30271,
                        dia: c.V_30272,
                        codigoPacienteNomePaciente: c.V_30296,
                        tIPOMaterial: c.V_30306,
                        uNIDADEDENEGOCIO: c.V_30307,
                        validCadastro: c.V_30308,
                        codPacienteNomedoPacienteCodMedicamento: c.V_30320,
                        dataPRESCRICAO: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
                        tipoMedicamentosCodPaciente: c.V_30339,
                        medicametnoPrescrito: c.V_30356,
                        horaprimeiraDose: c.V_30367,
                        codigoMedicamentoPaciente: c.V_30401,
                        dataEVOLUCAO: this.glb.ddMMYYYYThhmmssToDate(c.V_31571),
                        Id_Paciente: c.V_29828,
                        Id_Medicamento: c.V_30263,
                        IdMedicamento_IdPaciente: c.V_30401,
                        interroperMedicacao: this.glb.getBoolean(c.V_32859),
                        interrupcaoMotivo: c.V_32860,
                        calc__isSOS: this.isSOS(c),
                        intervaloHoras: c.V_34906 || 0,
                        // alteracao barbara
                        horarios: c.V_30396,
                        // descricao: any;
                        dataInicio: this.glb.ddMMYYYYThhmmssToDate(c.V_31967),
                        dataFim: this.glb.ddMMYYYYThhmmssToDate(c.V_31968),
                        medicamentoControlado: c.V_32857,
                        profissional: c.V_32858,
                        tempoEstimado: c.V_32861,
                        quantTempoEstimado: c.V_32862,
                        //  codMedPacTM: any;
                        // cascataTmatHospe: any;
                        codigoHospede: c.V_32939,
                        intervaloDS: c.V_35051

                      } as IInterventionMedication)
                    );
                prescriptionMedication = {
                  id: group.key, // Para exibir a OcorrenciaNo ao inves do Código do cadastro
                  ocorrenciaNo: c.OcorrenciaNo,
                  codRg: c.V_32964,
                  residente: c.V_387,
                  dataNascimento: c.V_391,
                  nomemae: c.V_1608,
                  identificacao1: c.V_3328,
                  identificacao2: c.V_3329,
                  centroCusto: c.V_27993,
                  prontuario: c.V_28051,
                  idPaciente: c.V_29828,
                  codigo32935: c.V_32935,
                  titulo: c.V_32936,
                  habilitado: this.lib.getBoolean(c.V_32937),
                  nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_32938),
                  codHospedeTelaPrescricao: c.V_32939,
                  codNome: c.V_32940,
                  codigoHospede: c.V_32941,
                  codigoNome: c.V_32942,
                  responsavelNome: c.V_33963,
                  // responsavelRG: c.V_33964,
                  // codHospede: c.V_34279,
                  lstmedicationGrid,
                } as IPrescriptionMedication;
                // Uma vez que o item é formado, é emitido, mas o complete só será chamado quando todos os itens forem concluídos
                observer.next(prescriptionMedication);
              });
          },
            (er) =>
              console.log('PrescriptionMedication.getFromCadastroGrid: ', er),
            () => {
              observer.complete();
            }
          );
      });
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getFromCadastroGrid',
        error.message
      );
    }
    return of(null);
  }

  protected convertToValorTexto(
    medicationGrid: IInterventionMedication[]
  ): string {
    try {
      let valorTexto = '<![CDATA[<Items>';
      if (medicationGrid) {
        medicationGrid.forEach((f) => {
          valorTexto += `<Item><index>${f.Id_Medicamento || f.guid }</index>`;
          if (f.medicamento)
            valorTexto += `<V_828>${f.medicamento}</V_828>`;
          if (f.tempoUso) valorTexto += `<V_830>${f.tempoUso}</V_830>`;
          if (f.dosagem) valorTexto += `<V_832>${f.dosagem}</V_832>`;
          if (f.via) valorTexto += `<V_2949>${f.via}</V_2949>`;
          if (f.orientacoes) valorTexto += `<V_2952>${f.orientacoes}</V_2952>`;
          if (f.posologia) valorTexto += `<V_3530>${f.posologia}</V_3530>`;
          if (f.centrocusto)
            valorTexto += `<V_27993>${f.centrocusto}</V_27993>`;
          if (f.tIPOMaterial)
            valorTexto += `<V_28018>${f.tIPOMaterial}</V_28018>`;
          if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
          if (f.apresentacao)
            valorTexto += `<V_29834>${f.apresentacao}</V_29834>`;
          if (f.quantUtilizadaD)
            valorTexto += `<V_29838>${f.quantUtilizadaD}</V_29838>`;
          if (f.codigo) valorTexto += `<V_30392>${f.codigo}</V_30392>`;
          if (f.titulo) valorTexto += `<V_30393>${f.titulo}</V_30393>`;
          if (f.habilitado) valorTexto += `<V_30214>${f.habilitado}</V_30214>`;
          if (f.Id_Medicamento)
            valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
          if (f.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO)
            valorTexto += `<V_30264>${f.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO}</V_30264>`;
          if (f.centroCustoIDCentroCusto)
            valorTexto += `<V_30266>${f.centroCustoIDCentroCusto}</V_30266>`;
          // if (f.cHECKDEMEDICAMENTOS)
          //   valorTexto += `<V_30277>${f.cHECKDEMEDICAMENTOS}</V_30277>`;
          if (f.codigoPacienteNomePaciente)
            valorTexto += `<V_30296>${f.codigoPacienteNomePaciente}</V_30296>`;
          if (f.codPacienteNomedoPacienteCodMedicamento)
            valorTexto += `<V_30320>${f.codPacienteNomedoPacienteCodMedicamento}</V_30320>`;
          if (f.dataPRESCRICAO)
            valorTexto += `<V_30330>${f.dataPRESCRICAO}</V_30330>`;
          if (f.tipoMedicamentosCodPaciente)
            valorTexto += `<V_30339>${f.tipoMedicamentosCodPaciente}</V_30339>`;
          if (f.medicametnoPrescrito)
            valorTexto += `<V_30356>${f.medicametnoPrescrito}</V_30356>`;
          if (f.horaprimeiraDose)
            valorTexto += `<V_30367>${f.horaprimeiraDose}</V_30367>`;
          if (f.horarios) valorTexto += `<V_30396>${f.horarios}</V_30396>`;
          if (f.IdMedicamento_IdPaciente)
            valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
          if (f.dataEVOLUCAO)
            valorTexto += `<V_31571>${f.dataEVOLUCAO}</V_31571>`;
          if (f.dataInicio) valorTexto += `<V_31967>${f.dataInicio}</V_31967>`;
          if (f.dataFim) valorTexto += `<V_31968>${f.dataFim}</V_31968>`;
          // if (f.codPAcienteNOVO)
          //   valorTexto += `<V_31969>${f.codPAcienteNOVO}</V_31969>`;
          if (f.medicamentoControlado)
            valorTexto += `<V_32857>${f.medicamentoControlado}</V_32857>`;
          if (f.profissional)
            valorTexto += `<V_32858>${f.profissional}</V_32858>`;
          // if (f.interromperMedicacaoTerapia)
          //   valorTexto += `<V_32859>${f.interromperMedicacaoTerapia}</V_32859>`;
          // if (f.motivoOrientacoes)
          //   valorTexto += `<V_32860>${f.motivoOrientacoes}</V_32860>`;
          if (f.tempoEstimado)
            valorTexto += `<V_32861>${f.tempoEstimado}</V_32861>`;
          if (f.ordem)
            valorTexto += `<V_104628>${f.ordem}</V_104628>`;
          // if (f.quantTEstimado)
          //   valorTexto += `<V_32862>${f.quantTEstimado}</V_32862>`;
          // if (f.mEDICAMENTOINAPROPRIADOPARAIDOSOS)
          //   valorTexto += `<V_32910>${f.mEDICAMENTOINAPROPRIADOPARAIDOSOS}</V_32910>`;
          // if (f.exame) valorTexto += `<V_32911>${f.exame}</V_32911>`;
          // if (f.mEDICAMENTOINAPROPRIADOPELOCLCR)
          //   valorTexto += `<V_32913>${f.mEDICAMENTOINAPROPRIADOPELOCLCR}</V_32913>`;
          // if (f.iNTERACAODROGADOENCA)
          //   valorTexto += `<V_32914>${f.iNTERACAODROGADOENCA}</V_32914>`;
          // if (f.codHospedeTelaPrescricao)
          //   valorTexto += `<V_32939>${f.codHospedeTelaPrescricao}</V_32939>`;
          // if (
          //   f.sCRIPTPARAVERIFICARQUALOTIPODAPOSOLOGIAESELECIONARQUALHORARIOEXIBIRSEOHORARIODA1DOSEOUOPERSONALIZADO
          // )
          //   valorTexto += `<V_34286>${f.sCRIPTPARAVERIFICARQUALOTIPODAPOSOLOGIAESELECIONARQUALHORARIOEXIBIRSEOHORARIODA1DOSEOUOPERSONALIZADO}</V_34286>`;
          // if (f.validade) valorTexto += `<V_34465>${f.validade}</V_34465>`;
          // if (f.materialID) valorTexto += `<V_34709>${f.materialID}</V_34709>`;
          if (f.intervaloDS)
            valorTexto += `<V_35051>${f.intervaloDS}</V_35051>`;
          // if (f.gOTASPMl) valorTexto += `<V_101099>${f.gOTASPMl}</V_101099>`;
          // if (f.consumoDiario)
          //   valorTexto += `<V_101103>${f.consumoDiario}</V_101103>`;
          // if (f.quantPorHorario)
          //   valorTexto += `<V_104223>${f.quantPorHorario}</V_104223>`;

          valorTexto += '</Item>';
        });
      }
      valorTexto += '</Items>]]>';
      return valorTexto;
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'convertToValorTexto',
        error.message
      );
    }
    return '';
  }

  /* Checa se a posologia é SOS. */
  protected isSOS(item: any): boolean {
    try {
      return this.glb.isEqual(item.V_3530, 'SOS');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSOS', error.message);
    }
  }


}
