import { clearPerson } from '@medlogic/medlogic/medlogic-state';
import { clearTenant } from '@medlogic/medlogic/medlogic-state';
import { clearPatients, loadApp, setIsLoading } from '@medlogic/medlogic/medlogic-state';
import { EnViewMode, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalMsgPtBR } from '../../service/local-msg-ptBR.service';
import { AppMedlogicPwaCuidadoState } from '../../ngrx/states/app-state';
import { select, Store } from '@ngrx/store';
import { doLoginAndLoadRoot } from '../../ngrx/actions/login.actions';
import { LocalLoginService } from '../../service/local-login.service';
import { ActivatedRoute } from '@angular/router';
import { LogService, EnLoginState } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../../pwa/service/config-pwa-medlogic.custom.service';
import { LocalNavigationService } from '../../service/local-navigation.service';
import { Observable } from 'rxjs';
import { selectEnLoginState, selectIsLoading, selectUserName } from '../../ngrx/selectors/login.selectors';
import { first, tap } from 'rxjs/operators';

import { Screenfull } from 'screenfull';
import * as screenfull from 'screenfull';

@Component({
  selector: 'ml-local-login-view',
  templateUrl: './local-login-view.component.html',
  styleUrls: ['./local-login-view.component.css']
})
export class LocalLoginViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  msgs: any = [];
  isLoading$: Observable<boolean> = this.store.pipe(select(selectIsLoading));
  loginState$: Observable<EnLoginState> = this.store.pipe(select(selectEnLoginState));
  userName$: Observable<string> = this.store.pipe(select(selectUserName));
  login: string;
  password: string;

  public get version(): string {
    return '4.1.0';
  }

  // public get loginState(): string {
  //   if (this.isLoading) { return EnLoginState.Loading; }
  //   return this.cnf.isLogged() ? EnLoginState.Logged : EnLoginState.Login;
  // }

  // public get userName(): string {
  //   return this.cnf.usuarioLogadoNome || '';
  // }

  constructor(
    private log: LogService,
    private cnf: ConfigPwaMedLogicService,
    private loginSrv: LocalLoginService,
    private nav: LocalNavigationService,
    private msg: LocalMsgPtBR,
    private snackBar: MatSnackBar,
    private store: Store<AppMedlogicPwaCuidadoState>,
    private route: ActivatedRoute,
    private glb: GlobalService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[login] LocalLoginView');
      this.cnf.showMenu = false;
      this.store.dispatch(loadApp({ title: 'Login', isLoading: false, enViewMode: EnViewMode.fullscreen }));
      // TODO: na verdade, precisava limpar todos os caches.
      this.store.dispatch(clearTenant());
      this.store.dispatch(clearPatients());
      this.store.dispatch(clearPerson());
      this.subs.sink = this.userName$.pipe(first(), tap(userName => this.login = userName)).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Vai para a página de início do projeto, uma vez logado.
  * Também força o fullscreen caso disponível.
  */
  protected goToRoot(tenantId: number): void {
    try {
      this.toogleFullscreen();
      this.cnf.showMenu = true;
      this.nav.gotoRoot();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'goToRoot', error.message);
    }
  }

  /* Ativa/desativa fullscreen se estiver disponível.
  * Por motivo de segurança do navegador, deve ser chamado a partir de um evento do usuário.
  */
  protected toogleFullscreen(forceEnable: boolean = true): void {
    try {
      const sf = screenfull as Screenfull;
      if (sf.isEnabled && ((forceEnable && !sf.isFullscreen) || !forceEnable)) {
        sf.toggle();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toogleFullscreen', error.message);
    }
  }

  /* Muda o foco para outro elemento. */
  setFocus(newElement: any): void {
    try {
      newElement.focus();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setFocus', error.message);
    }
  }

  /* Entrar com o mesmo usuário já logado.
  * Se houver problema no token ou configuração, exibe tela de login e mensagem de falha.
  */
  doEnter($event: any, loginState: EnLoginState): void {
    try {
      if (loginState === EnLoginState.Logged) {
        this.goToRoot(this.cnf.tenantId);
      } else {
        this.logoff();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doEnter', error.message);
    }
  }

  /* Botão para mudar o usuário. */
  doChangeUser($event: any): void {
    try {
      this.logoff();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doEnter', error.message);
    }
  }

  /* Faz o login, baseado em usuário em senha.
  * O token do config será gravado durante a chamada de getLogin.
  * Retornará true se login positivo.
  */
  onConfirm(userName: string, password: string): void {
    try {
      if (this.glb.isNullOrEmpty(userName) || this.glb.isNullOrEmpty(password)) {
        this.onLoginFail();
        return;
      }

      this.store.dispatch(setIsLoading({ isLoading: true }));
      this.toogleFullscreen();
      this.logoff(true);
      this.store.dispatch(doLoginAndLoadRoot({ userName, password }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirm', error.message);
    }
  }

  /* Exibe a mensagem de erro no caso de falha. */
  protected onLoginFail() {
    try {
      this.store.dispatch(setIsLoading({ isLoading: false }));
      this.msgs.push({ severity: 'error', summary: this.msg.LOGIN_FAIL_TITLE, detail: this.msg.LOGIN_FAIL });
      setTimeout(() => this.msgs = [], 2000);
      this.snackBar.open(this.msg.LOGIN_FAIL, '', {
        duration: 2000,
      });
      this.logoff();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onLoginFail', error.message);
    }
  }

  /** Call all necessary methods to clean the user session, cookie and tenant.  */
  logoff(isLoading: boolean = false): void {
    try {
      this.loginSrv.logoff(isLoading);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'logoff', error.message);
    }
  }


}
