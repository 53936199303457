import { IMedicationState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IInterventionMedication, IMedication } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import * as MedicationActions from './medication.actions';

export const medicationsFeatureKey = 'medication';

export const adapterMedication: EntityAdapter<IMedication> = createEntityAdapter<IMedication>({
  selectId: instance => getMedicationId(instance) // Id pode não existir no início
  // selectId: instance => `${instance.medicationId}_${instance.prescribedTime}` // Id pode não existir no início
  // selectId: instance => instance.medicationId // TODO: Checar se é necessário combinar o prescribed time devido a medicações repetidas no mesmo horário
});

/** Atenção: medication.reducer é utilizado tanto no Sistema quanto PWA.
 * No entanto, o Sistema requer que o identificador seja o ID apenas, para que não se repitam medicamentos.
 * No PWA, é necessária uma chave composta para que os desdobramentos em horários sejam considerados.
 * Para distinção: Sistema envia IInterventionMedication que tem guid e não tem medicationId.
 * PWA envia IMedication e possui medicationId.
 * Ambos possuem ocorrenciaNo
 */
export const getMedicationId = (medication: IMedication | IInterventionMedication): string => {
  try {
    // return `${medication.medicationId}_${medication.prescribedTime}`;
    // return `${medication?.medicationName}_${medication?.dosage}_${medication?.prescribedTime}`;
    const isSistema = !medication['medicationId'];
    if (isSistema) {
      return (medication.ocorrenciaNo > 0 ? medication.ocorrenciaNo.toString() : undefined) ||
        (medication['medicationId'] > 0 ? medication['medicationId'].toString() : undefined) ||
        (medication?.guid /*Sistema*/);
    }
    return (`${(medication as IMedication).medicationId}_${(medication as IMedication).prescribedTime}_${(medication as IMedication).dosage}` /* PWA */);
  } catch (error) {
    console.log('medication.reducer', 'getMedicationId', error.message);
  }
  return null;
}

export const initialStateMedication: IMedicationState = adapterMedication.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: undefined,
  numOfDaysToCheckOcurrency: 10,
  confirmItem: undefined,
  isSaving: false,
  rawMedications: undefined,
  historyMedications: undefined,
  prescriptionId: undefined,
  selectedMedication: undefined,
  allMedications: undefined,
  isLoading: false,
  keyword: undefined
});

export const reducer = createReducer(
  initialStateMedication,
  on(MedicationActions.addMedication,
    (state, action) => {
      const lastMedicationOrder = Object.keys(state.entities)
        .reduce((order, key) => order >= state.entities[key].order ? order : state.entities[key].order, 0);
      return adapterMedication.addOne({ ...action.medication, order: lastMedicationOrder }, state);
    }
  ),
  on(MedicationActions.loadMedications,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(MedicationActions.loadRawMedications,
    (state, action) => ({ ...state })
  ),
  on(MedicationActions.loadMedicationsBySelectedPatient,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(MedicationActions.loadMedicationsByPrescription,
    (state, action) => ({ ...state, allMedications: null, selectedMedication: null, prescriptionId: action.prescriptionId })
  ),
  on(MedicationActions.upsertMedication,
    (state, action) => adapterMedication.upsertOne(action.medication, state)
  ),
  on(MedicationActions.addMedications,
    // AddMany é problemático, pois, caso haje medicações excluídas, elas permaneceriam
    // (state, action) => adapterMedication.addMany(action.medications, state)
    (state, action) => adapterMedication.setAll(action.medications, state)
  ),
  // on(MedicationActions.addMedicationsandInsertStock,
  //   (state, action) => adapterMedication.addOne(action.medication, state)
  // ),
  on(MedicationActions.upsertMedications,
    (state, action) => adapterMedication.upsertMany(action.medications, state)
  ),
  on(MedicationActions.updateMedication,
    (state, action) => adapterMedication.updateOne(action.medication, state)
  ),
  on(MedicationActions.updateMedications,
    (state, action) => adapterMedication.updateMany(action.medications, state)
  ),
  on(MedicationActions.deleteMedication,
    (state, action) => adapterMedication.removeOne(getMedicationId(action?.medication), state)
  ),
  on(MedicationActions.deleteMedications,
    (state, action) => adapterMedication.removeMany(action?.medications?.map(m => getMedicationId(m)), state)
  ),
  on(MedicationActions.setMedicationsWasChanged,
    (state, { wasChanged }) => adapterMedication.map(m => ({ ...m, wasChanged }), state)
  ),
  on(MedicationActions.loadMedicationsSuccess,
    (state, action) => adapterMedication.setMany(action.medications, state)
  ),
  on(MedicationActions.loadRawMedicationsSuccess,
    (state, action) => ({ ...state, rawMedications: action?.rawMedications })
  ),
  on(MedicationActions.loadHistoryMedicationsSuccess,
    (state, action) => ({ ...state, historyMedications: action?.historyMedications })
  ),
  on(MedicationActions.medicationFail,
    (state, action) => ({
      ...state,
      medications: null,
      error: action?.error
    })
  ),
  on(MedicationActions.rawMedicationFail,
    (state, action) => ({
      ...state,
      rawMedications: null,
      error: action?.error
    })
  ),
  on(MedicationActions.historyMedicationFail,
    (state, action) => ({
      ...state,
      historyMedications: undefined,
      error: action?.error
    })
  ),
  on(MedicationActions.confirmMedication,
    (state, action) => adapterMedication.upsertOne({ ...action.medication, isSaving: true }, { ...state, confirmItem: action }) // { medication: action?.medication, patient: action?.patient, observation: action?.observation, updateStock: action?.updateStock })
  ),
  on(MedicationActions.confirmAllMedications,
    (state, action) => {
      return adapterMedication.upsertMany(action.medications, { ...state });
    } // { medication: action?.medication, patient: action?.patient, observation: action?.observation, updateStock: action?.updateStock })
  ),
  on(MedicationActions.cancelMedication,
    (state, action) => {
      const update: Update<IMedication> = {
        id: getMedicationId(action.medication),
        changes: {
          isEditing: false
        }
      };
      return adapterMedication.updateOne(update, state);
    }
  ),
  on(MedicationActions.clearMedications,
    state => initialStateMedication
  ),
  on(MedicationActions.cleanWasChangedMedications,
    (state, action) => adapterMedication.map(m => ({ ...m, wasChanged: false }), state)
  ),
  on(MedicationActions.saveMedicationSuccess,
    (state, { id, guid, cor }) => {
      const isNewMedicationCreateForTheFirstTime = (guid && !state.entities[id]);
      if (isNewMedicationCreateForTheFirstTime) {
        const medicationToChangeId = {
          ...state.entities[guid],
          medicationId: id,
          ocorrenciaNo: id,
          isNew: true,
          wasChanged: false,
          cor
        }
        const ids = [...state.ids].filter(id => id !== guid).map(m => +m);
        const entities = { ...state.entities };
        delete entities[guid];
        return {
          ...state,
          entities: { ...entities, [id]: medicationToChangeId },
          ids: [...ids, id],
          changeItem: null,
          error: null
        }
      }
      return {
        ...state,
        entities: { ...state.entities, [id]: { ...state.entities[id], cor } },
        changeItem: null,
        error: null
      }

      // const update: Update<IMedication> = {
      //   id: guid || id,
      //   changes: {
      //     id,
      //     wasChanged: false
      //   }
      // };
      // return adapterMedication.updateOne(update, state);
    }
  ),
  // on(MedicationActions.saveMedicationSuccess, // TODO: repetido, irá executar ambos ou só o primeiro?
  //   (state, action) => ({
  //     ...state,
  //     changeItem: null,
  //     error: null
  //   })
  // ),
  on(MedicationActions.setIsLoadingMedication,
    (state, action) => ({
      ...state,
      isLoading: action?.isLoading
    })
  ),
  on(MedicationActions.setMedicationsKeyword,
    (state, action) => ({
      ...state,
      keyword: action?.keyword
    })
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterMedication.getSelectors();
