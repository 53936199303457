export interface IMedicationCheckin {
    index?: any;
    cascataCheckIDClienteIDMedicamentoDataAtual: any;
    horaPrescricao: string;
    status: string;
    observacoes: string;
    statusmedicacao: string;
    dataCheckin: Date;
    diaPrescricao: string;
    identificacao: string;
    codigo: string;
    titulo: string;
    habilitado: boolean;
    codigoMedicamentoPaciente: string;
    profissional: string;
    codigoPaciente: number;
    horaCheckin: string;
    // modificacoes Rony
    Id_Paciente: string;
    Id_Medicamento: string;
    IdMedicamento_IdPaciente: string;
    tomou: boolean;
}
